import { useEffect, useState } from "react";
import { api, updateUser, getUserRamdom } from "@demo/shared-resources";
import "./index.css";

export default function Root(props) {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSend = (event) => {
    const nuevaURL = new URL("?path=pedidos", window.location.origin);
    nuevaURL.searchParams.append("name", name);
    window.location.href = nuevaURL.toString();
  };

  const getRequestUser = async () => {
    setIsLoading(true);
    const { status, data } = await getUserRamdom();
    if (status) {
      updateUser(data);
    } else {
      alert("Error", "No se pudo consultar el usuario");
    }
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <section>
      microfront 2
      <br />
      <input
        type="text"
        name="name"
        placeholder="Nombre"
        className="ml-2 custom-input"
        onChange={(event) => setName(event.target.value)}
        required
      />
      <button className="custom-button" onClick={onSend}>
        Enviar
      </button>
      <br />
      <button
        className={`custom-button mt-4 ml-2`}
        disabled={isLoading}
        onClick={() => getRequestUser()}
      >
        Consultar otro usuario
      </button>
    </section>
  );
}
